.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.stepper-item .step-counter {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d6d6d6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.stepper-item.active .step-counter {
  background-color: rgba(var(--bs-primary-rgb),1)!important;
}

.stepper-item.completed .step-counter {
  background-color: #28a745;
}

.stepper-item .step-label {
  margin-top: 8px;
  font-size: 0.9rem;
  text-align: center;
}

.stepper-item .step-counter svg {
  font-size: 1.2rem;
}

.progress-bar-wrapper {
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #28a745;
  transition: width 0.4s ease;
}
