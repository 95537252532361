/* src/layouts/AuthLayout.css */

#auth .row #auth-right {
    background-image: url(https://cdn.cirenio.com/commerces/login-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

#auth .row #auth-left .auth-logo img {
    height: 5rem;
}

#auth .row #auth-left .auth-title {
    font-size: 3rem;   
}

#auth .cirenio-logo {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 150px;
    opacity: 0.9;
}