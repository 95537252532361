/* src/components/PurchaseFlow/Step/PaymentStep.css */

.payment-widget-wrapper {
  position: relative;
  min-height: 680px;
}

.payment-widget-wrapper .react-loading-skeleton {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
  
/* Estilos para el widget de pago */
.payment-widget {
  max-width: 450px;
  height: 680px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mensaje de error */
.alert {
  max-width: 450px;
  margin: 20px auto; /* Centra el mensaje */
}

/* Botones de navegación */
.btn-success:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

/* Responsividad */
@media (max-width: 768px) {
  .payment-widget {
    max-width: 90%;
    max-height: 600px;
  }

  .alert {
    max-width: 90%;
  }
}
