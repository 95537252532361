.homonym-card {
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #ddd;
  }
  
  .homonym-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
.main-action {
    transition: all 0.3s ease;
    border: 1px var(--bs-card-bg) solid;
  }

  .main-action:hover {
    box-shadow: 0 8px 24px rgba(0, 102, 255, 0.15);
    transform: translateY(-3px);
  }

  div#purchase:hover, div#purchase.selected {
    border: 1px #198754 solid;
  }

  div#payment:hover, div#payment.selected {
    border: 1px #6c757d solid;
  }
