/* src/components/PaymentFlow/PaymentFlow.css */

.loan-card {
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
  }
  
  .loan-card .card-header {
    border-radius: inherit;
  }

  .loan-card:hover {
    background-color: #f8f9fa;
    border-color: #28a745;
  }

  .loan-card .toggle-icon {
    transition: transform 0.3s;
  }

  .loan-card.expanded .toggle-icon {
    transform: rotate(90deg);
    color: #28a745;
  }

  .loan-card.selected {
    border-color: #28a745;
    background-color: #e6ffe6;
  }

  .form-check-input:checked {
    background-color: #28a745;
    border-color: #28a745;
  }

  .list-group-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0.785rem;
    border: none;
    border-bottom: 1px solid #e9ecef;
  }

  /* Limitar la altura del carrito de pagos y permitir scroll en las cuotas */
  .carrito-scroll {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 15px;
  }

  /* Estilo de las opciones de métodos de pago */
  .payment-methods {
    margin-top: 1.5rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .payment-method {
    display: inline-block;
    width: 70px;
    height: 60px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, border-color 0.3s ease;
    /* Centramos el contenido del div */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payment-method img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .payment-method.selected {
    border-color: #28a745;
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.5);
  }

  .btn-disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .form-check-input {
    margin: 0;
    width: 1rem;
    height: 1rem;
  }

  .monto-a-pagar {
    width: 80px;
    margin: 0;
    padding: 0.2rem;
    font-size: 0.875rem;
  }

  .input-error {
    border-color: red !important;
    background-color: #f8d7da !important;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .monto-a-pagar {
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
