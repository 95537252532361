/* Estilo para las tarjetas de categoría */
.category-option {
  width: 100%;
  height: 150px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px; 
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-option.selected {
  border-color: #28a745;
  background-color: #e6f9ee;
}

.category-option:hover {
  box-shadow: 0 8px 24px rgba(0, 102, 255, 0.15);
  transform: translateY(-3px);
  border-color: #0066ff;
}

.category-option:hover .card-title {
  color: #0066ff;
}

.category-option:hover .card-body {
  color: #007bff;
}

.category-option i {
  font-size: 2.5rem;
  color: #6c757d;
  margin-bottom: 5px;
}

.category-option .card-title {
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 0;
  color: #555;
}

.category-option.selected i {
  color: #28a745;
}

.category-option.selected .card-title {
  color: #28a745;
}

.offer-option {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px; 
  transition: all 0.4s ease;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.offer-option:hover {
  box-shadow: 0 8px 24px rgba(0, 102, 255, 0.15);
  transform: translateY(-3px);
  border-color: #0066ff;
}

.offer-option.selected {
  border-color: #28a745;
  background-color: rgba(0, 204, 153, 0.05);
  box-shadow: 0 8px 24px rgba(0, 204, 153, 0.2);
}

.offer-option .card-title {
  font-size: 1.5rem;
  color: #333;
}

.offer-option .card-text {
  color: #555;
}

.offer-option:hover .card-title {
  color: #0066ff;
}

.offer-option:hover .card-text {
  color: #007bff;
}